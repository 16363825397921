// source: yourpass/demotool/v1/template.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.yourpass.demotool.v1.Template', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.TemplateIssue', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.Template = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.Template, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.Template.displayName = 'proto.yourpass.demotool.v1.Template';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.TemplateIssue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.demotool.v1.TemplateIssue.repeatedFields_, null);
};
goog.inherits(proto.yourpass.demotool.v1.TemplateIssue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.TemplateIssue.displayName = 'proto.yourpass.demotool.v1.TemplateIssue';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.Template.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.Template.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.Template} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.Template.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dynamicDataJsonSchema: (f = msg.getDynamicDataJsonSchema()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    images: (f = msg.getImages()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    jsonTemplate: (f = msg.getJsonTemplate()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    languages: (f = msg.getLanguages()) && google_protobuf_struct_pb.ListValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.Template}
 */
proto.yourpass.demotool.v1.Template.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.Template;
  return proto.yourpass.demotool.v1.Template.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.Template} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.Template}
 */
proto.yourpass.demotool.v1.Template.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setDynamicDataJsonSchema(value);
      break;
    case 5:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setImages(value);
      break;
    case 6:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setJsonTemplate(value);
      break;
    case 7:
      var value = new google_protobuf_struct_pb.ListValue;
      reader.readMessage(value,google_protobuf_struct_pb.ListValue.deserializeBinaryFromReader);
      msg.setLanguages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.Template.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.Template.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.Template} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.Template.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDynamicDataJsonSchema();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getImages();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getJsonTemplate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getLanguages();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_struct_pb.ListValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.Template.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.Template} returns this
 */
proto.yourpass.demotool.v1.Template.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.yourpass.demotool.v1.Template.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.Template} returns this
 */
proto.yourpass.demotool.v1.Template.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.yourpass.demotool.v1.Template.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.Template} returns this
 */
proto.yourpass.demotool.v1.Template.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Struct dynamic_data_json_schema = 4;
 * @return {?proto.google.protobuf.Struct}
 */
proto.yourpass.demotool.v1.Template.prototype.getDynamicDataJsonSchema = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 4));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.yourpass.demotool.v1.Template} returns this
*/
proto.yourpass.demotool.v1.Template.prototype.setDynamicDataJsonSchema = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.demotool.v1.Template} returns this
 */
proto.yourpass.demotool.v1.Template.prototype.clearDynamicDataJsonSchema = function() {
  return this.setDynamicDataJsonSchema(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.demotool.v1.Template.prototype.hasDynamicDataJsonSchema = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Struct images = 5;
 * @return {?proto.google.protobuf.Struct}
 */
proto.yourpass.demotool.v1.Template.prototype.getImages = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 5));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.yourpass.demotool.v1.Template} returns this
*/
proto.yourpass.demotool.v1.Template.prototype.setImages = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.demotool.v1.Template} returns this
 */
proto.yourpass.demotool.v1.Template.prototype.clearImages = function() {
  return this.setImages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.demotool.v1.Template.prototype.hasImages = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Struct json_template = 6;
 * @return {?proto.google.protobuf.Struct}
 */
proto.yourpass.demotool.v1.Template.prototype.getJsonTemplate = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 6));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.yourpass.demotool.v1.Template} returns this
*/
proto.yourpass.demotool.v1.Template.prototype.setJsonTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.demotool.v1.Template} returns this
 */
proto.yourpass.demotool.v1.Template.prototype.clearJsonTemplate = function() {
  return this.setJsonTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.demotool.v1.Template.prototype.hasJsonTemplate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.ListValue languages = 7;
 * @return {?proto.google.protobuf.ListValue}
 */
proto.yourpass.demotool.v1.Template.prototype.getLanguages = function() {
  return /** @type{?proto.google.protobuf.ListValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.ListValue, 7));
};


/**
 * @param {?proto.google.protobuf.ListValue|undefined} value
 * @return {!proto.yourpass.demotool.v1.Template} returns this
*/
proto.yourpass.demotool.v1.Template.prototype.setLanguages = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.demotool.v1.Template} returns this
 */
proto.yourpass.demotool.v1.Template.prototype.clearLanguages = function() {
  return this.setLanguages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.demotool.v1.Template.prototype.hasLanguages = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.demotool.v1.TemplateIssue.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.TemplateIssue.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.TemplateIssue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.TemplateIssue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.TemplateIssue.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    issuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.TemplateIssue}
 */
proto.yourpass.demotool.v1.TemplateIssue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.TemplateIssue;
  return proto.yourpass.demotool.v1.TemplateIssue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.TemplateIssue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.TemplateIssue}
 */
proto.yourpass.demotool.v1.TemplateIssue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.TemplateIssue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.TemplateIssue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.TemplateIssue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.TemplateIssue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string template_id = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.TemplateIssue.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.TemplateIssue} returns this
 */
proto.yourpass.demotool.v1.TemplateIssue.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string issues = 2;
 * @return {!Array<string>}
 */
proto.yourpass.demotool.v1.TemplateIssue.prototype.getIssuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yourpass.demotool.v1.TemplateIssue} returns this
 */
proto.yourpass.demotool.v1.TemplateIssue.prototype.setIssuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yourpass.demotool.v1.TemplateIssue} returns this
 */
proto.yourpass.demotool.v1.TemplateIssue.prototype.addIssues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.demotool.v1.TemplateIssue} returns this
 */
proto.yourpass.demotool.v1.TemplateIssue.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};


goog.object.extend(exports, proto.yourpass.demotool.v1);
