import React from "react";
import { DemotoolServiceClient } from "../api/demotool/yourpass/demotool/v1/Demotool_serviceServiceClientPb";

interface ApiContextInterface {
  client: DemotoolServiceClient;
}

export const ApiContext = React.createContext<ApiContextInterface>({
  client: new DemotoolServiceClient(""),
});

export const ApiContextProvider = (
  props: React.PropsWithChildren<{ apiURL: string }>
) => {
  const { apiURL, children } = props;

  const ctx: ApiContextInterface = {
    client: new DemotoolServiceClient(apiURL),
  };

  return <ApiContext.Provider value={ctx}>{children}</ApiContext.Provider>;
};
