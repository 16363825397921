/**
 * @fileoverview gRPC-Web generated client stub for yourpass.demotool.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as yourpass_demotool_v1_demotool_service_pb from '../../../yourpass/demotool/v1/demotool_service_pb';


export class DemotoolServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateSession = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_demotool_v1_demotool_service_pb.CreateSessionResponse,
    (request: yourpass_demotool_v1_demotool_service_pb.CreateSessionRequest) => {
      return request.serializeBinary();
    },
    yourpass_demotool_v1_demotool_service_pb.CreateSessionResponse.deserializeBinary
  );

  createSession(
    request: yourpass_demotool_v1_demotool_service_pb.CreateSessionRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_demotool_v1_demotool_service_pb.CreateSessionResponse>;

  createSession(
    request: yourpass_demotool_v1_demotool_service_pb.CreateSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.CreateSessionResponse) => void): grpcWeb.ClientReadableStream<yourpass_demotool_v1_demotool_service_pb.CreateSessionResponse>;

  createSession(
    request: yourpass_demotool_v1_demotool_service_pb.CreateSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.CreateSessionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.demotool.v1.DemotoolService/CreateSession',
        request,
        metadata || {},
        this.methodInfoCreateSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.demotool.v1.DemotoolService/CreateSession',
    request,
    metadata || {},
    this.methodInfoCreateSession);
  }

  methodInfoGetScenarioNames = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesResponse,
    (request: yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesRequest) => {
      return request.serializeBinary();
    },
    yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesResponse.deserializeBinary
  );

  getScenarioNames(
    request: yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesResponse>;

  getScenarioNames(
    request: yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesResponse) => void): grpcWeb.ClientReadableStream<yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesResponse>;

  getScenarioNames(
    request: yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetScenarioNamesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.demotool.v1.DemotoolService/GetScenarioNames',
        request,
        metadata || {},
        this.methodInfoGetScenarioNames,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.demotool.v1.DemotoolService/GetScenarioNames',
    request,
    metadata || {},
    this.methodInfoGetScenarioNames);
  }

  methodInfoGetScenario = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_demotool_v1_demotool_service_pb.GetScenarioResponse,
    (request: yourpass_demotool_v1_demotool_service_pb.GetScenarioRequest) => {
      return request.serializeBinary();
    },
    yourpass_demotool_v1_demotool_service_pb.GetScenarioResponse.deserializeBinary
  );

  getScenario(
    request: yourpass_demotool_v1_demotool_service_pb.GetScenarioRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_demotool_v1_demotool_service_pb.GetScenarioResponse>;

  getScenario(
    request: yourpass_demotool_v1_demotool_service_pb.GetScenarioRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetScenarioResponse) => void): grpcWeb.ClientReadableStream<yourpass_demotool_v1_demotool_service_pb.GetScenarioResponse>;

  getScenario(
    request: yourpass_demotool_v1_demotool_service_pb.GetScenarioRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetScenarioResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.demotool.v1.DemotoolService/GetScenario',
        request,
        metadata || {},
        this.methodInfoGetScenario,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.demotool.v1.DemotoolService/GetScenario',
    request,
    metadata || {},
    this.methodInfoGetScenario);
  }

  methodInfoSubscribeScenario = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_demotool_v1_demotool_service_pb.SubscribeScenarioResponse,
    (request: yourpass_demotool_v1_demotool_service_pb.SubscribeScenarioRequest) => {
      return request.serializeBinary();
    },
    yourpass_demotool_v1_demotool_service_pb.SubscribeScenarioResponse.deserializeBinary
  );

  subscribeScenario(
    request: yourpass_demotool_v1_demotool_service_pb.SubscribeScenarioRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/yourpass.demotool.v1.DemotoolService/SubscribeScenario',
      request,
      metadata || {},
      this.methodInfoSubscribeScenario);
  }

  methodInfoGetScenarioIssues = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesResponse,
    (request: yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesRequest) => {
      return request.serializeBinary();
    },
    yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesResponse.deserializeBinary
  );

  getScenarioIssues(
    request: yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesResponse>;

  getScenarioIssues(
    request: yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesResponse) => void): grpcWeb.ClientReadableStream<yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesResponse>;

  getScenarioIssues(
    request: yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetScenarioIssuesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.demotool.v1.DemotoolService/GetScenarioIssues',
        request,
        metadata || {},
        this.methodInfoGetScenarioIssues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.demotool.v1.DemotoolService/GetScenarioIssues',
    request,
    metadata || {},
    this.methodInfoGetScenarioIssues);
  }

  methodInfoGetPass = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_demotool_v1_demotool_service_pb.GetPassResponse,
    (request: yourpass_demotool_v1_demotool_service_pb.GetPassRequest) => {
      return request.serializeBinary();
    },
    yourpass_demotool_v1_demotool_service_pb.GetPassResponse.deserializeBinary
  );

  getPass(
    request: yourpass_demotool_v1_demotool_service_pb.GetPassRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_demotool_v1_demotool_service_pb.GetPassResponse>;

  getPass(
    request: yourpass_demotool_v1_demotool_service_pb.GetPassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetPassResponse) => void): grpcWeb.ClientReadableStream<yourpass_demotool_v1_demotool_service_pb.GetPassResponse>;

  getPass(
    request: yourpass_demotool_v1_demotool_service_pb.GetPassRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetPassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.demotool.v1.DemotoolService/GetPass',
        request,
        metadata || {},
        this.methodInfoGetPass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.demotool.v1.DemotoolService/GetPass',
    request,
    metadata || {},
    this.methodInfoGetPass);
  }

  methodInfoGetTemplate = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_demotool_v1_demotool_service_pb.GetTemplateResponse,
    (request: yourpass_demotool_v1_demotool_service_pb.GetTemplateRequest) => {
      return request.serializeBinary();
    },
    yourpass_demotool_v1_demotool_service_pb.GetTemplateResponse.deserializeBinary
  );

  getTemplate(
    request: yourpass_demotool_v1_demotool_service_pb.GetTemplateRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_demotool_v1_demotool_service_pb.GetTemplateResponse>;

  getTemplate(
    request: yourpass_demotool_v1_demotool_service_pb.GetTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetTemplateResponse) => void): grpcWeb.ClientReadableStream<yourpass_demotool_v1_demotool_service_pb.GetTemplateResponse>;

  getTemplate(
    request: yourpass_demotool_v1_demotool_service_pb.GetTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.GetTemplateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.demotool.v1.DemotoolService/GetTemplate',
        request,
        metadata || {},
        this.methodInfoGetTemplate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.demotool.v1.DemotoolService/GetTemplate',
    request,
    metadata || {},
    this.methodInfoGetTemplate);
  }

  methodInfoUpdatePassTemplate = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateResponse,
    (request: yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateRequest) => {
      return request.serializeBinary();
    },
    yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateResponse.deserializeBinary
  );

  updatePassTemplate(
    request: yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateResponse>;

  updatePassTemplate(
    request: yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateResponse) => void): grpcWeb.ClientReadableStream<yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateResponse>;

  updatePassTemplate(
    request: yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: yourpass_demotool_v1_demotool_service_pb.UpdatePassTemplateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.demotool.v1.DemotoolService/UpdatePassTemplate',
        request,
        metadata || {},
        this.methodInfoUpdatePassTemplate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.demotool.v1.DemotoolService/UpdatePassTemplate',
    request,
    metadata || {},
    this.methodInfoUpdatePassTemplate);
  }

}

