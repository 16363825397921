interface ConfigFromBackend {
  basePreviewImageUrl: string;
}

declare global {
  interface Window {
    APP_CONFIG?: ConfigFromBackend;
  }
}

declare const window: Window;

export const PUBLIC_URL: string = "/";

export const API_URL: string = "";

export const BASE_PREVIEW_IMAGE_URL: string | undefined =
  process.env.REACT_APP_BASE_PREVIEW_IMAGE_URL ||
  window.APP_CONFIG?.basePreviewImageUrl;
