import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { SnackbarProvider } from "notistack";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import { ApiContextProvider } from "./context";
import { API_URL, PUBLIC_URL } from "./utils/config";

const Home = lazy(() => import("./routes/Home"));
const SessionPage = lazy(() => import("./routes/SessionPage"));
const Status = lazy(() => import("./routes/Status"));

export const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});

function App() {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <IntlProvider messages={{}} locale="en" defaultLocale="en">
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <React.StrictMode>
            <ApiContextProvider apiURL={API_URL}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <Router basename={PUBLIC_URL}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route path="/status/:projectId?" component={Status} />
                    <Route path="/:pin/:passId" children={<SessionPage />} />
                  </Switch>
                </Suspense>
              </Router>
            </ApiContextProvider>
          </React.StrictMode>
        </SnackbarProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
