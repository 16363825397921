// source: yourpass/demotool/v1/demotool_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var yourpass_demotool_v1_template_pb = require('../../../yourpass/demotool/v1/template_pb.js');
goog.object.extend(proto, yourpass_demotool_v1_template_pb);
var yourpass_demotool_v1_pass_pb = require('../../../yourpass/demotool/v1/pass_pb.js');
goog.object.extend(proto, yourpass_demotool_v1_pass_pb);
var yourpass_demotool_v1_scenario_pb = require('../../../yourpass/demotool/v1/scenario_pb.js');
goog.object.extend(proto, yourpass_demotool_v1_scenario_pb);
goog.exportSymbol('proto.yourpass.demotool.v1.CreateSessionRequest', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.CreateSessionResponse', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetPassRequest', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetPassResponse', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetScenarioIssuesRequest', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetScenarioIssuesResponse', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetScenarioNamesRequest', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetScenarioNamesResponse', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetScenarioRequest', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetScenarioResponse', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetTemplateRequest', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.GetTemplateResponse', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.SubscribeScenarioRequest', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.SubscribeScenarioResponse', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.UpdatePassTemplateRequest', null, global);
goog.exportSymbol('proto.yourpass.demotool.v1.UpdatePassTemplateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.CreateSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.CreateSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.CreateSessionRequest.displayName = 'proto.yourpass.demotool.v1.CreateSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.CreateSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.CreateSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.CreateSessionResponse.displayName = 'proto.yourpass.demotool.v1.CreateSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.demotool.v1.GetScenarioNamesRequest.repeatedFields_, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetScenarioNamesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetScenarioNamesRequest.displayName = 'proto.yourpass.demotool.v1.GetScenarioNamesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetScenarioNamesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetScenarioNamesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetScenarioNamesResponse.displayName = 'proto.yourpass.demotool.v1.GetScenarioNamesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetScenarioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetScenarioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetScenarioRequest.displayName = 'proto.yourpass.demotool.v1.GetScenarioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetScenarioResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetScenarioResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetScenarioResponse.displayName = 'proto.yourpass.demotool.v1.GetScenarioResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.SubscribeScenarioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.SubscribeScenarioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.SubscribeScenarioRequest.displayName = 'proto.yourpass.demotool.v1.SubscribeScenarioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.SubscribeScenarioResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.SubscribeScenarioResponse.displayName = 'proto.yourpass.demotool.v1.SubscribeScenarioResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetScenarioIssuesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetScenarioIssuesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetScenarioIssuesRequest.displayName = 'proto.yourpass.demotool.v1.GetScenarioIssuesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.demotool.v1.GetScenarioIssuesResponse.repeatedFields_, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetScenarioIssuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetScenarioIssuesResponse.displayName = 'proto.yourpass.demotool.v1.GetScenarioIssuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetPassRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetPassRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetPassRequest.displayName = 'proto.yourpass.demotool.v1.GetPassRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetPassResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetPassResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetPassResponse.displayName = 'proto.yourpass.demotool.v1.GetPassResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetTemplateRequest.displayName = 'proto.yourpass.demotool.v1.GetTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.GetTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.GetTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.GetTemplateResponse.displayName = 'proto.yourpass.demotool.v1.GetTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.UpdatePassTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.UpdatePassTemplateRequest.displayName = 'proto.yourpass.demotool.v1.UpdatePassTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.demotool.v1.UpdatePassTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.demotool.v1.UpdatePassTemplateResponse.displayName = 'proto.yourpass.demotool.v1.UpdatePassTemplateResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.CreateSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.CreateSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.CreateSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.CreateSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scenarioPin: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.CreateSessionRequest}
 */
proto.yourpass.demotool.v1.CreateSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.CreateSessionRequest;
  return proto.yourpass.demotool.v1.CreateSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.CreateSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.CreateSessionRequest}
 */
proto.yourpass.demotool.v1.CreateSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScenarioPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.CreateSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.CreateSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.CreateSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.CreateSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScenarioPin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scenario_pin = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.CreateSessionRequest.prototype.getScenarioPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.CreateSessionRequest} returns this
 */
proto.yourpass.demotool.v1.CreateSessionRequest.prototype.setScenarioPin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.CreateSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.CreateSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.CreateSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.CreateSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    passId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.CreateSessionResponse}
 */
proto.yourpass.demotool.v1.CreateSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.CreateSessionResponse;
  return proto.yourpass.demotool.v1.CreateSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.CreateSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.CreateSessionResponse}
 */
proto.yourpass.demotool.v1.CreateSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.CreateSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.CreateSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.CreateSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.CreateSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pass_id = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.CreateSessionResponse.prototype.getPassId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.CreateSessionResponse} returns this
 */
proto.yourpass.demotool.v1.CreateSessionResponse.prototype.setPassId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetScenarioNamesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetScenarioNamesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pinsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetScenarioNamesRequest}
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetScenarioNamesRequest;
  return proto.yourpass.demotool.v1.GetScenarioNamesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetScenarioNamesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetScenarioNamesRequest}
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetScenarioNamesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetScenarioNamesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPinsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string pins = 1;
 * @return {!Array<string>}
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.prototype.getPinsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yourpass.demotool.v1.GetScenarioNamesRequest} returns this
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.prototype.setPinsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yourpass.demotool.v1.GetScenarioNamesRequest} returns this
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.prototype.addPins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.demotool.v1.GetScenarioNamesRequest} returns this
 */
proto.yourpass.demotool.v1.GetScenarioNamesRequest.prototype.clearPinsList = function() {
  return this.setPinsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetScenarioNamesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetScenarioNamesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetScenarioNamesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioNamesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scenarioNamesMap: (f = msg.getScenarioNamesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetScenarioNamesResponse}
 */
proto.yourpass.demotool.v1.GetScenarioNamesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetScenarioNamesResponse;
  return proto.yourpass.demotool.v1.GetScenarioNamesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetScenarioNamesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetScenarioNamesResponse}
 */
proto.yourpass.demotool.v1.GetScenarioNamesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getScenarioNamesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetScenarioNamesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetScenarioNamesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetScenarioNamesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioNamesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScenarioNamesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> scenario_names = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yourpass.demotool.v1.GetScenarioNamesResponse.prototype.getScenarioNamesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yourpass.demotool.v1.GetScenarioNamesResponse} returns this
 */
proto.yourpass.demotool.v1.GetScenarioNamesResponse.prototype.clearScenarioNamesMap = function() {
  this.getScenarioNamesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetScenarioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetScenarioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetScenarioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pin: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetScenarioRequest}
 */
proto.yourpass.demotool.v1.GetScenarioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetScenarioRequest;
  return proto.yourpass.demotool.v1.GetScenarioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetScenarioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetScenarioRequest}
 */
proto.yourpass.demotool.v1.GetScenarioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetScenarioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetScenarioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetScenarioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pin = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.GetScenarioRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.GetScenarioRequest} returns this
 */
proto.yourpass.demotool.v1.GetScenarioRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetScenarioResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetScenarioResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetScenarioResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scenario: (f = msg.getScenario()) && yourpass_demotool_v1_scenario_pb.Scenario.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetScenarioResponse}
 */
proto.yourpass.demotool.v1.GetScenarioResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetScenarioResponse;
  return proto.yourpass.demotool.v1.GetScenarioResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetScenarioResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetScenarioResponse}
 */
proto.yourpass.demotool.v1.GetScenarioResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new yourpass_demotool_v1_scenario_pb.Scenario;
      reader.readMessage(value,yourpass_demotool_v1_scenario_pb.Scenario.deserializeBinaryFromReader);
      msg.setScenario(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetScenarioResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetScenarioResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetScenarioResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScenario();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      yourpass_demotool_v1_scenario_pb.Scenario.serializeBinaryToWriter
    );
  }
};


/**
 * optional Scenario scenario = 1;
 * @return {?proto.yourpass.demotool.v1.Scenario}
 */
proto.yourpass.demotool.v1.GetScenarioResponse.prototype.getScenario = function() {
  return /** @type{?proto.yourpass.demotool.v1.Scenario} */ (
    jspb.Message.getWrapperField(this, yourpass_demotool_v1_scenario_pb.Scenario, 1));
};


/**
 * @param {?proto.yourpass.demotool.v1.Scenario|undefined} value
 * @return {!proto.yourpass.demotool.v1.GetScenarioResponse} returns this
*/
proto.yourpass.demotool.v1.GetScenarioResponse.prototype.setScenario = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.demotool.v1.GetScenarioResponse} returns this
 */
proto.yourpass.demotool.v1.GetScenarioResponse.prototype.clearScenario = function() {
  return this.setScenario(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.demotool.v1.GetScenarioResponse.prototype.hasScenario = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.SubscribeScenarioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.SubscribeScenarioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.SubscribeScenarioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.SubscribeScenarioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pin: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.SubscribeScenarioRequest}
 */
proto.yourpass.demotool.v1.SubscribeScenarioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.SubscribeScenarioRequest;
  return proto.yourpass.demotool.v1.SubscribeScenarioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.SubscribeScenarioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.SubscribeScenarioRequest}
 */
proto.yourpass.demotool.v1.SubscribeScenarioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.SubscribeScenarioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.SubscribeScenarioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.SubscribeScenarioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.SubscribeScenarioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pin = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.SubscribeScenarioRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.SubscribeScenarioRequest} returns this
 */
proto.yourpass.demotool.v1.SubscribeScenarioRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.SubscribeScenarioResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.SubscribeScenarioResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scenario: (f = msg.getScenario()) && yourpass_demotool_v1_scenario_pb.Scenario.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.SubscribeScenarioResponse}
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.SubscribeScenarioResponse;
  return proto.yourpass.demotool.v1.SubscribeScenarioResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.SubscribeScenarioResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.SubscribeScenarioResponse}
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new yourpass_demotool_v1_scenario_pb.Scenario;
      reader.readMessage(value,yourpass_demotool_v1_scenario_pb.Scenario.deserializeBinaryFromReader);
      msg.setScenario(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.SubscribeScenarioResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.SubscribeScenarioResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScenario();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      yourpass_demotool_v1_scenario_pb.Scenario.serializeBinaryToWriter
    );
  }
};


/**
 * optional Scenario scenario = 1;
 * @return {?proto.yourpass.demotool.v1.Scenario}
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse.prototype.getScenario = function() {
  return /** @type{?proto.yourpass.demotool.v1.Scenario} */ (
    jspb.Message.getWrapperField(this, yourpass_demotool_v1_scenario_pb.Scenario, 1));
};


/**
 * @param {?proto.yourpass.demotool.v1.Scenario|undefined} value
 * @return {!proto.yourpass.demotool.v1.SubscribeScenarioResponse} returns this
*/
proto.yourpass.demotool.v1.SubscribeScenarioResponse.prototype.setScenario = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.demotool.v1.SubscribeScenarioResponse} returns this
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse.prototype.clearScenario = function() {
  return this.setScenario(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.demotool.v1.SubscribeScenarioResponse.prototype.hasScenario = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetScenarioIssuesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetScenarioIssuesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioIssuesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesRequest}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetScenarioIssuesRequest;
  return proto.yourpass.demotool.v1.GetScenarioIssuesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetScenarioIssuesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesRequest}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetScenarioIssuesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetScenarioIssuesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioIssuesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesRequest} returns this
 */
proto.yourpass.demotool.v1.GetScenarioIssuesRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetScenarioIssuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    templateIssuesList: jspb.Message.toObjectList(msg.getTemplateIssuesList(),
    yourpass_demotool_v1_template_pb.TemplateIssue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetScenarioIssuesResponse;
  return proto.yourpass.demotool.v1.GetScenarioIssuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIssues(value);
      break;
    case 2:
      var value = new yourpass_demotool_v1_template_pb.TemplateIssue;
      reader.readMessage(value,yourpass_demotool_v1_template_pb.TemplateIssue.deserializeBinaryFromReader);
      msg.addTemplateIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetScenarioIssuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTemplateIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      yourpass_demotool_v1_template_pb.TemplateIssue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string issues = 1;
 * @return {!Array<string>}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.getIssuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse} returns this
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.setIssuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse} returns this
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.addIssues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse} returns this
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};


/**
 * repeated TemplateIssue template_issues = 2;
 * @return {!Array<!proto.yourpass.demotool.v1.TemplateIssue>}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.getTemplateIssuesList = function() {
  return /** @type{!Array<!proto.yourpass.demotool.v1.TemplateIssue>} */ (
    jspb.Message.getRepeatedWrapperField(this, yourpass_demotool_v1_template_pb.TemplateIssue, 2));
};


/**
 * @param {!Array<!proto.yourpass.demotool.v1.TemplateIssue>} value
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse} returns this
*/
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.setTemplateIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.yourpass.demotool.v1.TemplateIssue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yourpass.demotool.v1.TemplateIssue}
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.addTemplateIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.yourpass.demotool.v1.TemplateIssue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.demotool.v1.GetScenarioIssuesResponse} returns this
 */
proto.yourpass.demotool.v1.GetScenarioIssuesResponse.prototype.clearTemplateIssuesList = function() {
  return this.setTemplateIssuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetPassRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetPassRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetPassRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetPassRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetPassRequest}
 */
proto.yourpass.demotool.v1.GetPassRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetPassRequest;
  return proto.yourpass.demotool.v1.GetPassRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetPassRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetPassRequest}
 */
proto.yourpass.demotool.v1.GetPassRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetPassRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetPassRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetPassRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetPassRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.GetPassRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.GetPassRequest} returns this
 */
proto.yourpass.demotool.v1.GetPassRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetPassResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetPassResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetPassResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetPassResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pass: (f = msg.getPass()) && yourpass_demotool_v1_pass_pb.Pass.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetPassResponse}
 */
proto.yourpass.demotool.v1.GetPassResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetPassResponse;
  return proto.yourpass.demotool.v1.GetPassResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetPassResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetPassResponse}
 */
proto.yourpass.demotool.v1.GetPassResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new yourpass_demotool_v1_pass_pb.Pass;
      reader.readMessage(value,yourpass_demotool_v1_pass_pb.Pass.deserializeBinaryFromReader);
      msg.setPass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetPassResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetPassResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetPassResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetPassResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPass();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      yourpass_demotool_v1_pass_pb.Pass.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pass pass = 1;
 * @return {?proto.yourpass.demotool.v1.Pass}
 */
proto.yourpass.demotool.v1.GetPassResponse.prototype.getPass = function() {
  return /** @type{?proto.yourpass.demotool.v1.Pass} */ (
    jspb.Message.getWrapperField(this, yourpass_demotool_v1_pass_pb.Pass, 1));
};


/**
 * @param {?proto.yourpass.demotool.v1.Pass|undefined} value
 * @return {!proto.yourpass.demotool.v1.GetPassResponse} returns this
*/
proto.yourpass.demotool.v1.GetPassResponse.prototype.setPass = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.demotool.v1.GetPassResponse} returns this
 */
proto.yourpass.demotool.v1.GetPassResponse.prototype.clearPass = function() {
  return this.setPass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.demotool.v1.GetPassResponse.prototype.hasPass = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetTemplateRequest}
 */
proto.yourpass.demotool.v1.GetTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetTemplateRequest;
  return proto.yourpass.demotool.v1.GetTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetTemplateRequest}
 */
proto.yourpass.demotool.v1.GetTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.GetTemplateRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.GetTemplateRequest} returns this
 */
proto.yourpass.demotool.v1.GetTemplateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.GetTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.GetTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.GetTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && yourpass_demotool_v1_template_pb.Template.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.GetTemplateResponse}
 */
proto.yourpass.demotool.v1.GetTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.GetTemplateResponse;
  return proto.yourpass.demotool.v1.GetTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.GetTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.GetTemplateResponse}
 */
proto.yourpass.demotool.v1.GetTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new yourpass_demotool_v1_template_pb.Template;
      reader.readMessage(value,yourpass_demotool_v1_template_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.GetTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.GetTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.GetTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.GetTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      yourpass_demotool_v1_template_pb.Template.serializeBinaryToWriter
    );
  }
};


/**
 * optional Template template = 1;
 * @return {?proto.yourpass.demotool.v1.Template}
 */
proto.yourpass.demotool.v1.GetTemplateResponse.prototype.getTemplate = function() {
  return /** @type{?proto.yourpass.demotool.v1.Template} */ (
    jspb.Message.getWrapperField(this, yourpass_demotool_v1_template_pb.Template, 1));
};


/**
 * @param {?proto.yourpass.demotool.v1.Template|undefined} value
 * @return {!proto.yourpass.demotool.v1.GetTemplateResponse} returns this
*/
proto.yourpass.demotool.v1.GetTemplateResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.demotool.v1.GetTemplateResponse} returns this
 */
proto.yourpass.demotool.v1.GetTemplateResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.demotool.v1.GetTemplateResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.UpdatePassTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.UpdatePassTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    passId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.UpdatePassTemplateRequest}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.UpdatePassTemplateRequest;
  return proto.yourpass.demotool.v1.UpdatePassTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.UpdatePassTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.UpdatePassTemplateRequest}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.UpdatePassTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.UpdatePassTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string pass_id = 1;
 * @return {string}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.prototype.getPassId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.UpdatePassTemplateRequest} returns this
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.prototype.setPassId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string template_id = 2;
 * @return {string}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.demotool.v1.UpdatePassTemplateRequest} returns this
 */
proto.yourpass.demotool.v1.UpdatePassTemplateRequest.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.demotool.v1.UpdatePassTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.demotool.v1.UpdatePassTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pass: (f = msg.getPass()) && yourpass_demotool_v1_pass_pb.Pass.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.demotool.v1.UpdatePassTemplateResponse}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.demotool.v1.UpdatePassTemplateResponse;
  return proto.yourpass.demotool.v1.UpdatePassTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.demotool.v1.UpdatePassTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.demotool.v1.UpdatePassTemplateResponse}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new yourpass_demotool_v1_pass_pb.Pass;
      reader.readMessage(value,yourpass_demotool_v1_pass_pb.Pass.deserializeBinaryFromReader);
      msg.setPass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.demotool.v1.UpdatePassTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.demotool.v1.UpdatePassTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPass();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      yourpass_demotool_v1_pass_pb.Pass.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pass pass = 1;
 * @return {?proto.yourpass.demotool.v1.Pass}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.prototype.getPass = function() {
  return /** @type{?proto.yourpass.demotool.v1.Pass} */ (
    jspb.Message.getWrapperField(this, yourpass_demotool_v1_pass_pb.Pass, 1));
};


/**
 * @param {?proto.yourpass.demotool.v1.Pass|undefined} value
 * @return {!proto.yourpass.demotool.v1.UpdatePassTemplateResponse} returns this
*/
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.prototype.setPass = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.demotool.v1.UpdatePassTemplateResponse} returns this
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.prototype.clearPass = function() {
  return this.setPass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.demotool.v1.UpdatePassTemplateResponse.prototype.hasPass = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.yourpass.demotool.v1);
